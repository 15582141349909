import React, { Suspense, useState, useEffect } from "react";

import menus from "../../Menu/Menu.json";
import sustainables_23 from "../../Menu/year_23/Sustainability_23.json";
import sustainables_24 from "../../Menu/year_24/Sustainability_24.json";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import "./Menu.scss";
const iconPath = process.env.PUBLIC_URL + "/assets/docs/";
const iconPathImg = process.env.PUBLIC_URL + "/assets/images/";
export default function Menu(props) {
  const [show, setShow] = useState(true);
  const navigate = useHistory();
  const location = useLocation();
  const [showState, setState] = useState(localStorage.getItem("menuState"));
  const [listOfMenu, setListOfMenu] = useState(menus);
  useEffect(() => {
    if (
      navigate.location.pathname == "/sustainability-23" ||
      navigate.location.pathname == "/event-agenda-23" ||
      navigate.location.pathname == "/support-23"
    ) {
      setListOfMenu(sustainables_23);
    }else  if (
      navigate.location.pathname == "/sustainability-24" ||
      navigate.location.pathname == "/event-agenda-24" ||
      navigate.location.pathname == "/support-24"
    ) {
      setListOfMenu(sustainables_24);
    } else {
      setListOfMenu(menus);
    }
  });
  const navigate1 = (link, e) => {
    if (link != "") {
      setShow(false);
    }

    if (link != location.pathname && link != "") {
      navigate.push(link);
    }
  };

  return (
    <div className="menu">
      <nav>
        <div class="navbar">
          <div class="nav-links">
            <div class="sidebar-logo"></div>
            <ul class="links">
              {listOfMenu["menus"].map((links, index) => (
                <li>
                  <a
                    target="new"
                    href={
                      links.link == "/event-report-23"
                        ? `${iconPath}Mercedes-Benz_Sustainability_Dialogue_India_2023_Report.pdf`
                        : links["title"] == "MBRDI on LinkedIn" &&
                          "https://www.linkedin.com/company/mercedes-benz-research-and-development-india/"
                    }
                    class="primary-link"
                    onMouseOver={() => {
                      setShow(true);
                    }}
                    onClick={(e) =>
                      links.title != "Event Report" &&
                      navigate1(links["link"], e)
                    }
                  >
                    {links["title"]}
                  </a>

                  {links["hasSubmenu"] && show && (
                    <ul className="htmlCss-sub-menu sub-menu">
                      {links["subMenu"].map((subLinks) => (
                        <li class="more">
                          <a
                            onClick={(e) => navigate1(subLinks["link"], e)}
                            className="submenu-link"
                          >
                            <span class="sub-menu-title">
                              {subLinks["title"]}
                            </span>
                            {(subLinks["title"] == "Corporate Governance" ||
                              subLinks["title"] ==
                                "Sustainability Dialogue India") && (
                              <div>
                                {" "}
                                <img
                                  className="arrow-img"
                                  src={`${iconPathImg}common/arrow_right.png`}
                                />
                                <img
                                  className="arrow-img-hover"
                                  src={`${iconPathImg}common/arrow_right_hover.png`}
                                />
                              </div>
                            )}
                          </a>
                          {subLinks["hasSubmenu"] &&
                            subLinks["title"] == "Corporate Governance" && (
                              <ul class="more-sub-menu sub-menu">
                                {subLinks["subMenu"].map((subLinksMenu) => (
                                  <li>
                                    <a
                                      target="new"
                                      href={
                                        subLinksMenu.title ==
                                        "Annual Return FY21-22"
                                          ? `${iconPath}Annual-Return_1.pdf`
                                          : subLinksMenu.title ==
                                            "Annual Return FY22-23"
                                          ? `${iconPath}Annual-Return_2.pdf`
                                          : `${iconPath}AGM_Notice.pdf`
                                      }
                                      className="sub-menu-link"
                                    >
                                      <span class="sub-menu-sub">
                                        {subLinksMenu["title"]}
                                      </span>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            )}
                          {subLinks["hasSubmenu"] &&
                            subLinks["title"] ==
                              "Sustainability Dialogue India" && (
                              <ul class="more-sub-menu sub-menu">
                                {subLinks["subMenu"].map((subLinksMenu) => (
                                  <li>
                                    <a
                                      target="new"
                                      onClick={(e) =>
                                        navigate1(subLinksMenu["link"], e)
                                      }
                                      className="sub-menu-link"
                                    >
                                      <span class="sub-menu-sub">
                                        {subLinksMenu["title"]}
                                      </span>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
